body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: "Myriad Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

@supports (-ms-ime-align:auto) {
  body {
    font-family: "Myriad Pro", sans-serif;
  }
}

@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro"), url('./Font/MYRIADPRO-REGULAR.woff') format('woff');
  }
  



.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  width: 100%;
}

.carousel{
  min-width: 100%;
}

.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  
}

.discovery-button {
  background-color: #009999;
  color: white;
  font-family: "Myriad Pro";
  border: none;
  font-size: 10pt;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@media (min-width: 768px) {
  .discovery-button {
    font-size: 32pt;
  }
}
