.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #009999 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }
  
  .nav-tabs .nav-link.active {
    border-bottom: none !important;
    background-color: #009999 !important;
    color: white !important;
  }